//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Options from './sharedIconProps';

export default {
  name: 'CheckoutTrackers',
  props: { ...Options.props },
  data() {
    return {
      viewBox: '0 0 24 22',
    };
  },
};
